import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {Helmet} from 'react-helmet';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import useSiteMetadata from '../components/SiteMetadata';

// eslint-disable-next-line
export const ContentPageTemplate = ({ title, content, contentComponent }) => {
    const PageContent = contentComponent || Content;

    return (
        <section className="section section--pastel section--with-mask mask-four">
            <div className="container sitteroo-blog-post">
                <div className="content box">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="section">
                                <h1 className="title is-size-3 has-text-weight-bold is-bold-light">
                                    {title}
                                </h1>
                                <PageContent
                                    className="content"
                                    content={content}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

ContentPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
};

const ContentPage = ({ data }) => {
    const { markdownRemark: post } = data;

    const { description } = useSiteMetadata();

    return (
        <Layout>
            <Helmet titleTemplate="%s">
                <title>{`${post.frontmatter.title}`}</title>
                <meta
                    name="description"
                    content={`${post.frontmatter.title} -  ${description}`}
                />
                <meta
                    name="og:description"
                    content={`${post.frontmatter.title} -  ${description}`}
                />
            </Helmet>

            <ContentPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                content={post.html}
            />
        </Layout>
    );
};

ContentPage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ContentPage;

export const contentPageQuery = graphql`
    query ContentPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
            }
        }
    }
`;
